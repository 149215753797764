import React from 'react'

import { Col, Row, Section } from '../components/grid'
import Layout from '../components/Layout'
import { H1, H4 } from 'components/typography/Headings'

export default function PrivacyPolicyPage() {
	return (
		<Layout pageName="Privacy Policy">
			<Section margin="mt-20">
				<Row center>
					<div className="prose prose-lg prose-gray px-4 sm:px-0">
						<H1>americanreading.com Privacy Policy</H1>
						<span className="font-semibold">Last Update: June 23, 2023</span>
						<p>
							Americanreading.com is the corporate website (site) of American Reading Company (ARC). ARC markets literacy instruction materials and associated
							services to schools and districts throughout the United States.
						</p>
						<p>
							At ARC, we respect and protect the privacy of our users. This Privacy Policy outlines how we collect, use, and protect personal information obtained
							from users of our website.{' '}
						</p>

						<H4>What information is collected by ARC on americanreading.com?</H4>
						<p>
							ARC may collect personal information required to provide services or products. We may also collect non-personal information and other technical details
							related to website usage. Information we may collect includes:
						</p>
						<ul>
							<li>
								<strong>Personal Information: </strong>such as name, email address, and phone number, when you provide it to us through our contact form, newsletter
								subscription, or webinar / event registration.
							</li>
							<li>
								<strong>Usage Data: </strong>such as IP address, browser type, and pages visited, automatically collected through third-party analytics tools such
								as Google Analytics, and other applications listed under the “Information Sharing” section of this document.
							</li>
							<li>
								<strong>Cookies: </strong>small data files that are placed on your device when you visit the Site, which allow us to recognize your browser and
								remember your preferences.
							</li>
						</ul>
						<p>We use this information to:</p>
						<ul>
							<li>Provide, operate, and maintain the Site;</li>
							<li>Respond to your inquiries, comments, and requests;</li>
							<li>Monitor and analyze usage and trends to improve our Site and services;</li>
							<li>Send you newsletters and promotional materials, with your consent.</li>
						</ul>
						<H4>Sharing Information</H4>
						<p>
							We do not sell, rent, or disclose our users' personal information to third parties unless required by law or to provide services requested by our users.
							We may share non-personal information, such as usage data with the following third-party partners for analytics, advertising, or other purposes:
						</p>
						<ul>
							<li>
								<strong>Sentry:</strong> error tracking and debugging service
							</li>
							<li>
								<strong>Supabase:</strong> database hosting and management service
							</li>
							<li>
								<strong>Netlify:</strong> web hosting and content delivery service
							</li>
							<li>
								<strong>Contentful:</strong> content management system service
							</li>
							<li>
								<strong>Google Analytics:</strong> web analytics service
							</li>
							<li>
								<strong>Google Cloud</strong>: cloud hosting and storage service
							</li>
							<li>
								<strong>LogRocket</strong>: user session recording and replay service
							</li>
							<li>
								<strong>Mailgun</strong>: email delivery and tracking service
							</li>
							<li>
								<strong>Salesforce</strong>: customer relationship management service
							</li>
							<li>
								<strong>Mailchimp</strong>: email marketing and automation service
							</li>
							<li>
								<strong>Vimeo</strong>: video hosting and sharing service
							</li>
							<li>
								<strong>YouTube</strong>: video hosting and sharing service
							</li>
						</ul>
						<p>
							These service providers may have access to your information as needed to perform their functions but are prohibited from using it for any other purpose.
						</p>
						<p>We may also disclose your information:</p>
						<ul>
							<li>To comply with applicable laws, regulations, or legal process;</li>
							<li>To enforce our Site policies or protect our or others' rights, property, or safety;</li>
							<li>In connection with a merger, acquisition, or sale of our assets.</li>
						</ul>
						<H4>Your Rights and Choices</H4>
						<p>You have the right to:</p>
						<ul>
							<li>Access, update, or delete your personal information, subject to certain exceptions and limitations;</li>
							<li>Object to the processing of your personal information;</li>
							<li>Withdraw your consent for processing your personal information, where applicable.</li>
						</ul>
						<p>To exercise any of these rights, please contact us using the contact information provided below.</p>
						<H4>Security</H4>
						<p>
							Security We take appropriate measures to protect our users' personal information from unauthorized access, alteration, disclosure, or destruction. We
							use secure servers and encryption technologies to safeguard sensitive information. However, no method of transmission over the internet or method of
							electronic storage is 100% secure.
						</p>
						<H4>Cookies</H4>
						<p>
							Cookies We may use cookies, web beacons, or other technologies to collect non-personal information about our users' interactions with our website.
							Cookies are small data files that are stored on a user's computer or device when they visit our website. We use cookies to personalize content, track
							usage, and improve our services.
						</p>
						<H4>Third-Party Websites</H4>
						<p>
							Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage our
							users to read the privacy policies of these websites before providing any personal information.
						</p>
						<H4>Children's Privacy</H4>
						<p>
							We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from
							a child under 13, we will delete that information.
						</p>
						<H4>How does American Reading Company notify you of changes to this privacy policy?</H4>
						<p>
							If changes are made to this privacy policy, a banner alerting users to the change will be displayed at the top of the screen on the Site. We reserve the
							right to update or modify this Privacy Policy at any time without prior notice. Continued use of our services after the changes constitute acceptance of
							the new Privacy Policy.
						</p>
						<H4>Is any of the data collected on americanreading.com used to drive targeted advertising?</H4>
						<p>ARC does not contain third-party advertising, and no data collected is used to drive targeted advertising in any way.</p>
						<H4>Contact Us</H4>
						<p>
							If you have any questions or concerns about this Privacy Policy, please contact us at{' '}
							<a href="mailto:privacy@americanreading.com" className="text-ab-100 hover:underline">
								privacy@americanreading.com
							</a>
							.
						</p>
					</div>
				</Row>
			</Section>
		</Layout>
	)
}
